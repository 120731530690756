.guarantee_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fefefe;
}

.guarantee_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.guarantee_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.guarantee_heading {
    color: var(--color-orange);
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
    width: 45%;
}

.guarantee_qna {
    display: flex;
    align-items: center;
    gap: 120px;
    align-self: stretch;
}

.guarantee_question {
    color: #000;
    font-family: Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
    width: 45%;
    align-self: stretch;
}

.guarantee_answer {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    flex: 1 0 0;
    align-self: stretch;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .guarantee_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .guarantee_content {
        width: 90%;
        padding: 0 5%;
    }

    .guarantee_qna {
        gap: 40px;
    }

    .guarantee_question {
        font-size: 2.7rem;
    }

    .guarantee_answer {
        padding-top: 3%;
    }
}

@media screen and (max-width: 600px) {
    .guarantee_qna {
        flex-direction: column;
    }

    .guarantee_question {
        width: 100%;
        font-size: 2.2rem;
        line-height: 3.5rem;
    }
}