.pricing_container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 48px;
    background: var(--color-white);
}

.pricing_components {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 64px;
    width: 100%;
    padding: 97.5px 10px;
}

.pricing_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.pricing_toggle p {
    color: #414141;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
}

.toggle_container {
    position: relative;
    top: 0;
    left: 0;
    width: 81.667px;
    height: 42px;
    background: #f1f1f1;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid #ececec;
    transition: 0.3s;
}

.toggle_container.active {
    background: var(--color-black);
}

.toggle_container.active .toggle_btn {
    left: 41px;
    box-shadow: none;
}

.toggle_btn {
    position: relative;
    top: 0;
    left: 1px;
    width: 35px;
    height: 35px;
    background: #fefefe;
    border-radius: 30px;
    cursor: pointer;
    z-index: 50;
    box-shadow: 0px 0px 10px 1px #cdcdcd;
    transition: 0.3s;
}

.month.active {
    text-decoration: underline;
    color: #000;
    font-weight: 600;
}

.annual.active {
    text-decoration: underline;
    color: #000;
    font-weight: 600;
}

.pricing_cards {
    display: flex;
    align-items: flex-start;
    gap: 56px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.pricing_card {
    display: flex;
    height: 870px;
    padding: 64px 36px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral, #FEFEFE);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
    width: 372px;
}

.plan_info {
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
}

.weekly_price {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.pricing_plan {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: 100%;
    padding-left: 15px;
}

.pricing_plan h2 {
    font-family: Helvetica;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.weekly_price h1 {
    color: var(--color-black);
    font-family: 'Roboto Mono', monospace;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.6px;
    align-self: stretch;
}

.weekly_price h3 {
    color: var(--color-grey);
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 57.6px;
}

.feature {
    display: flex;
    padding: 16px 0px;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--light-grey-subtitle, #C7C7C7);
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
    justify-content: space-between;
}

.plan_features {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--light-grey-subtitle, #C7C7C7);
}

.discount {
    color: var(--color-orange);
    font-family: 'Roboto Mono', monospace;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.pricing_btn.btn {
    font-family: 'Roboto Mono', monospace;
    background: var(--color-white);
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    color: var(--color-black);
}

.pricing_btn.btn:hover {
    background: var(--color-orange);
    color: var(--color-white);
}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .pricing_cards {
        gap: 20px;
    }
}

@media screen and (max-width: 600px) {
    .pricing_cards {
        flex-direction: column-reverse;
        gap: 60px;
    }

    .pricing_toggle {
        gap: 5px;
        padding: 0 20px;
    }

    .feature {
        font-size: 1rem;
    }

    .discount h4 {
        font-size: 1rem;
    }
    
    .price_card {
        padding: 64px 24px;
    }

    .plan_info {
        width: 260px;
    }

    .annual_card.open.pricing_card {
        padding: 32px 32px 64px 32px;
    }
}