.carousel {
    width: 100%;
    background: var(--color-black);
    padding: 97.5px 17.5%;
}

.testimonials_heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding-bottom: 5%;
}

.testimonials_heading h3 {
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
}

.testimonials_heading h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: #FEFEFE;
    font-family: Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.carousel_card {
    padding: 48px;
    background: var(--color-grey);
}

.carousel_slide h1 {
    color: #FEFEFE;
    font-family: Helvetica;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.carousel_slide p {
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding-bottom: 16px;
}

.customer_name {
    display: flex;
    padding: 16px 0px;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid #C7C7C7;
}

.customer_name p {
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px;
    flex: 1 0 0;
}

.slick-dots li button:before {
    color: white !important;
}

.slick-dots li.slick-active button:before {
    color: var(--color-orange) !important;
}

.testimonials_container {
    display: flex;
    width: 100%;
    padding: 97.5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-black);
}

.testimonials_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonials_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 48px;
}

.testimonial_cards {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.testimonial_card {
    display: flex;
    padding: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--color-grey);
    justify-content: space-between;
}

.testimonial h1 {
    align-self: stretch;
    color: #FEFEFE;
    font-family: Helvetica;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.testimonial p {
    align-self: stretch;
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .testimonials_inner {
        width: 1920px; 
        margin: 0 auto; 
    }
}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1500px) {
    .carousel_card {
        height: 410px !important;
    }
}

@media screen and (max-width: 600px) {
    .carousel {
        padding: 97.5px 10%;
    }

    .carousel_card {
        height: 600px !important;
    }

    .testimonials_heading h1 {
        font-size: 28px;
        line-height: 54px;
        padding-bottom: 20px;
    }

}