.servicesCTA_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
}

.servicesCTA_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.servicesCTA_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}


/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .servicesCTA_inner {
        width: 1920px;
        margin: 0 auto;
    }
}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .servicesCTA_content .CTA_container h1 {
        font-size: 2rem;
        line-height: 3rem;
    }

    .servicesCTA_content .CTA_container {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .servicesCTA_content .CTA_container h1 {
        font-size: 1.75rem;
        line-height: 3rem;
    }

    .servicesCTA_content .CTA_container p {
        font-size: 1rem;
    }
}