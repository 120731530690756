@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.container {
    display: flex;
    width: 100%;
    height: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background-color: #FEFEFE;
    z-index: 100;
}

.nav_container {
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;
}

.nav_inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_logo {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.logo {
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
}

.nav_contents {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.nav_content {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
    color: black;
}

.nav_content:hover {
    color: var(--color-orange);
}

.nav_btn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
    color: black;
}

.mobile_menu {
    margin: auto 0;
    display: none;
    cursor: pointer;
}

.hamburger .bar_1, .bar_2, .bar_3 {
    width: 46.666667px;
    height: 3px;
    background: none;
    margin: 8px 16px 8px 0;
    border: 2px solid black;
    transition: 0.4s;
}

.open .bar_1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 8px);
    transform: rotate(-45deg) translate(-8px, 8px);
}

.open .bar_2 {
    opacity: 0;
}

.open .bar_3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.open.mobile_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
}

.mobile_contents {
    display: none;
    position: fixed;
    top: 100px;
    left: 0;
    height: calc(100vh - 100px);
    width: 100%;
    z-index: 60;
}

.dropbtn {
    background: none;
    justify-content: space-between;
    cursor: pointer;
    min-width: 160px;
}

.dropdown {
    float: left;
    overflow: hidden;
    cursor: pointer;
}

.dropdown_content {
    display: none;
    position: absolute;
    text-align: left;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1rem;
    width: 160px;
    background: var(--color-white);
}

.dropdown:hover .dropdown_content {
    display: block;
    transition: 0.5s;
}

.dropdown_content a {
    display: block;
    padding: 16px 20px;
    width: 100%;
    color: black;
}

.dropdown_content a:hover {
    color: var(--color-orange);
}

.dropdown:hover .dropdown_arrow {
    rotate: 180deg;
    transition: 0.5s;
}

.dropdown:hover .dropbtn {
    color: var(--color-orange);
    background: var(--color-white);
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .nav_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .nav_contents {
        display: none;
    }

    .nav_container {
        width: 90%;
    }

    .mobile_menu {
        display: block;
    }

    .container {
        position: sticky;
        top: 0;
        left: 0;
    }


    .mobile_content {
        display: flex;
        padding-bottom: 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'Roboto Mono', monospace;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 19.2px;
        color: black;
    }

    .mobile_btn {
        display: flex;
        padding: 16px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'Roboto Mono', monospace;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 19.2px;
        color: black;
    }

    .mobile_contents {
        padding-bottom: 100px;
    }
}