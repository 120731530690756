.home_pricing_container {
    display: flex;
    width: 100%;
    padding: 97.5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #F8F8F9;
}

.home_pricing_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing_content {
    display: flex;
    width: 65%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.ribbon {
    display: none;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-orange);
    color: white;
    font-size: 0.7rem;
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    width: 42%;
    margin-left: auto;
}

.ribbon.open {
    display: flex;

}

.ribbon_container {
    width: 100%;
    text-align: right;
    display: flex;
}

.pricing_intro {
    display: flex;
    height: 656px;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    flex: 1 0 0;
    max-width: 25%;
}

.pricing_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.plan_header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: space-between;
}

.weekly_price h3 {
    padding-top: 10px;
}

.price_btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pricing_header h1 {
    color: #000;
    font-family: Helvetica;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.pricing_header p {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.price_btn {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px;
}

.price_component {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    max-width: 75%;
}

.price_toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    
}

.price_toggle p {
    color: #414141;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
}

.price_cards {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.price_card {
    display: flex;
    height: 754px;
    padding: 64px 32px;
    flex-direction: column;
    align-items: center;
    background: #FEFEFE;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
}

.annual_card.open {
    padding: 29.5px 32px 64px 32px;
}


@media screen and (min-width: 1921px) {
    .home_pricing_inner {
        width: 1920px;
        margin: 0 auto;
    }

}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1500px) {
    .pricing_content {
        width: 90%;
        flex-direction: column;
    }

    .pricing_intro {
        width: 100%;
        max-width: none;
    }

    .pricing_header {
        width: 100%;
    }

    .pricing_header h1 {
        font-size: 3rem;
    }

    .price_component {
        flex-direction: column;
        width: 100%;
        max-width: none;
    }

    .toggle_container {
        min-width: 81px;
    }

    .annual {
        justify-content: right;
        text-align: right;
    }

    .price_cards {
        width: 100%;
        justify-content: center;
    }

    .price_card {
        padding: 64px 32px;
        width: 364px !important;
    }

    .price_toggle {
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
}

@media screen and (max-width: 600px) {
    .pricing_content {
        width: 90%;
        flex-direction: column;
    }

    .pricing_intro {
        width: 100%;
        max-width: none;
        padding: 0 20px;
    }

    .pricing_header {
        width: 100%;
    }

    .pricing_header h1 {
        font-size: 3rem;
    }

    .price_component {
        flex-direction: column;
        width: 100%;
        max-width: none;
    }

    .price_toggle {
        gap: 5px;
        padding: 0 20px;
    }

    .toggle_container {
        min-width: 81px;
    }

    .annual {
        justify-content: right;
        text-align: right;
    }

    .price_cards {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        max-width: 100%;
    }

    .price_card {
        width: 324px;
    }

    .ribbon {
        width: 42%;
        font-size: 0.55rem;
    }
}