.companyCTA_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
}

.companyCTA_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.companyCTA_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

/* MEDIA QUERIES: LARGER THAN 1920PX */
@media screen and (min-width: 1921px) {
    .companyCTA_inner {
        max-width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .companyCTA_content .CTA_container h1 {
        font-size: 3rem;
        line-height: 3rem;
    }

    .companyCTA_content .CTA_container {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .companyCTA_content .CTA_container h1 {
        font-size: 1.75rem;
        line-height: 3rem;
    }

    .companyCTA_content .CTA_container p {
        font-size: 1rem;
    }
}