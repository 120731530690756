.home_container {
    display: flex;
    max-width: 1920px;
    max-height: 1080px;
    margin: auto;
    width: 100%;
    height: 800px;
    justify-content: center;
    align-items: center;
}

.home_content {
    display: flex;
    width: 65%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 180px;
}

.home_info {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
}

.home_info h1 {
    color: var(--dark-grey, #212121);
    font-family: Helvetica;
    font-size: 3.75vw;
    font-style: normal;
    font-weight: 600;
    line-height: 96px;
    letter-spacing: 1.6px;
}

.home_info p {
    color: var(--color-black);
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 56px 0;
}

.home_btn {
    color: var(--color-black);
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
}

.home_image {
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.home_image img {
    width: 500px;
    aspect-ratio: 1081/901;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .home_container {
        width: 1920px; 
        height: 1080px; 
        margin: 0 auto;
        overflow: hidden; 
    }

}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .home_content {
        width: 100%;
        padding: 0 5%;
        gap: 80px;
    }

    .home_info {
        width: 90%;
    }

    .home_info h1 {
        font-size: 3rem;
        line-height: 75px;
    }

    .home_image {
        justify-content: center;
        align-items: center;
    }

    .home_image img {
        width: 350px;
        aspect-ratio: 1081/901;
    }
}

@media screen and (max-width: 600px) {
    .home_container {
        height: 900px;
    }

    .home_content {
        flex-direction: column;
        width: 100%;
        gap: 50px;
    }

    .home_info {
        width: 100%;
        padding: 0 5%;
    }

    .home_info h1 {
        font-size: 3rem;
        line-height: 65px;
    }
}