.howCTA_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
}

.howCTA_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.howCTA_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.CTA_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 65%;
}

.CTA_container h1 {
    color: #fefefe;
    text-align: center;
    font-family: Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
    flex: 1 0 0;
}

.CTA_container p {
    color: #fefefe;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.CTA_btn.btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #fefefe;
    background: none;
    color: var(--neutral, #FEFEFE);
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px;
    cursor: pointer;
}

.CTA_btn.btn:hover {
    background: var(--color-orange);
    border: 1px solid var(--color-orange);
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .howCTA_inner {
        width: 1920px;
        margin: 0 auto;
    }
}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .CTA_container {
        width: 80%;
    }

    .CTA_container h1 {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }
}