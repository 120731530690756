.tooltip_container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: -25px;
    transform: translateX(40%);
    background: var(--color-grey);
    color: white;
    padding: 10px;
    border-radius: 1rem;
    font-size: 1rem;
    width: 220px;
    padding: 1rem;
    line-height: 1rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .tooltip_container {
        display: none;
    }

    .tooltip {
        display: none;
    }
}