.features_container {
    display: flex;
    width: 100%;
    padding: 97.5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #fefefe;
}

.features_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.features_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.card_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.feature_card {
    display: flex;
    padding: 48px;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    width: 100%;
    background: var(--color-white);
}

.feature_card_reverse {
    display: flex;
    padding: 48px;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    width: 100%;
    background: var(--color-white);
}

.card_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
}

.card_content h4 {
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 37.2px;
    letter-spacing: 0.62px;
    align-self: stretch;
}

.card_content p {
    color: var(--color-black);
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.card_image img {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .features_container {
        padding: 15% 10%;
    }

    .features_content {
        width: 100%;
    }

    .features_heading h1 {
        font-size: 2.2rem;
        line-height: 3.5rem;
    }

    .feature_card {
        flex-direction: column;
        gap: 30px;
    }

    .feature_card_reverse {
        flex-direction: column;
        gap: 30px;
    }

    .card_content p {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .features_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    .features_container {
        padding: 20% 10%;
    }

    .features_content {
        width: 100%;
    }

    .features_heading h1 {
        font-size: 2.2rem;
        line-height: 3.5rem;
    }

    .feature_card {
        flex-direction: column;
        gap: 30px;
    }

    .feature_card_reverse {
        flex-direction: column;
        gap: 30px;
    }

    .card_content p {
        font-size: 1rem;
    }
}