.faq_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 63px;
}

.faq_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 63px;
}

.faq_header {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.faq_header h1 {
    flex: 1 0 0;
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.faq_header p {
    flex: 1 0 0;
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.faq_list {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.faq {
    cursor: pointer;
}

.question {
    display: flex;
    width: 100%;
    padding: 32px 48px;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    background: var(--color-white);
    justify-content: space-between;
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    max-height: 124px;
}

.answer {
    display: flex;
    width: 100%;
    padding: 0 48px;
    align-items: flex-start;
    background: var(--color-white);
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease-in;
}

.active.answer {
    max-height: 1000px;
    padding: 0 48px 32px 48px;
    transition: all 0.1s ease-in;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .faq_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .faq_header h1 {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    .faq_header {
        width: 80%;
    }

    .faq_list {
        width: 80%
    }

    .question {
        padding: 5%;
        max-height: none;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .question {
        padding: 10%;
        font-size: 1rem;
    }

    .active.answer {
        padding: 0 10% 32px 10%;
    }

    .faq_list {
        width: 100%;
        gap: 4px;
    }

    .question {
        line-height: 24px;
    }

    .answer {
        font-size: 12px;
    }
}