.company_container {
    display: flex;
    width: 100%;
    padding: 97.5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-white);
}

.company_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.company_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.company_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .company_inner {
        max-width: 1920px;
        margin: 0 auto;
    }    
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .company_heading {
        font-size: 1rem;
        line-height: 3rem;
        padding: 0 1%;
    }

    .company_content {
        width: 90%;
    }
}