.pricingHeader_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    max-width: 1200px;
}

.pricingHeader_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.pricing_heading {
    flex: 1 0 0;
    color: var(--color-black);
    text-align: center;
    font-family: Helvetica;
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 96px;
    letter-spacing: 1.6px;
}

.pricing_subheading {
    width: 60%;
    color: #000;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.pricing_features {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.pricing_feature {
    display: flex;
    align-items: center;
    gap: 16px;
}

.checkbox {
    width: 24px;
    height: 24px;
}

.feature_statement {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .pricingHeader_content {
        width: 100%;
        padding: 0 5%;
    }

    .pricing_heading {
        width: 80%;
        font-size: 3rem;
    }

    .pricing_subheading {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .pricing_heading {
        font-size: 2.5rem;
        line-height: 4rem;
    }

    .pricing_features {
        display: grid;
        grid-template-columns: 45% 45%;
        gap: 30px;
    }
}