.qna_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
}

.qna_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.qna_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.qna_title {
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
    width: 45%;
}

.qna_section {
    display: flex;
    align-items: center;
    gap: 120px;
    align-self: stretch;
}

.qna_question {
    color: #FEFEFE;
    font-family: Helvetica;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
    width: 48%;
    align-self: stretch;
}

.qna_answer {
    color: #FEFEFE;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    flex: 1 0 0;
    align-self: stretch;
}

@media screen and (min-width: 1921px) {
    .qna_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .qna_content {
        width: 80%;
    }

    .qna_section {
        flex-direction: column;
        gap: 60px;
        width: 100%;
    }

    .qna_question {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .qna_title {
        width: 90%;
    }

    .qna_question {
        font-size: 2.5rem;
        line-height: 3.2rem;
    }
}