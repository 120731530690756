.mission_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
    color: var(--color-white);
}

.mission_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mission_box {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.mission_title {
    color: var(--color-white);
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: 21.6px
}

.mission_info {
    display: flex;
    align-items: center;
    gap: 120px;
    align-self: stretch;
}

.mission_heading {
    width: 47%;
    align-self: stretch;
    font-family: Helvetica;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.mission_content {
    flex: 1 0 0;
    align-self: stretch;
    color: var(--color-white);
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 45%;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .mission_inner {
        width: 1920px;
        margin: 0 auto;
    }

    .mission_box {
        width: 65%; /* Maintain the width percentage relative to the fixed container width */
    }

}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .mission_container {
        padding: 25% 10%;
    }

    .mission_box {
        width: 90%;
    }

    .mission_info {
        flex-direction: column;
        gap: 60px;
    }

    .mission_heading {
        width: 100%;
    }

    .mission_heading h1 {
        font-size: 3rem;
        line-height: 4rem;
    }

    .mission_content {
        width: 100%;
        line-height: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .mission_heading h1 {
        font-size: 2.2rem;
        line-height: 3rem;
    }
}