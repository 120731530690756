.header_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.header_title {
    flex: 1 0 0;
    color: var(--color-black);
    text-align: center;
    font-family: Helvetica;
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 96px;
    letter-spacing: 1.6px;
}

.header_subtitle {
    color: #000;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 60%;
}

@media screen and (min-width: 1921px) {
    .header_inner {
        width: 1920px;
        margin: 0 auto; 
    }

}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .header_subtitle {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .header_title {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }

    .header_content {
        width: 80%;
    }
}