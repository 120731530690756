.invite_container {
    display: flex;
    width: 100%;
    padding: 97.5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
}

.invite_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.invite_content {
    display: flex;
    width: 65%;
    align-items: center;
    gap: 120px;
    justify-content: space-between;
}

.invite_content img {
    width: 35%;
    height: 100%;
}

.invite_frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    flex: 1 0 0;
}

.invite_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.invite_info h4 {
    align-self: stretch;
    color: #000;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.2px;
    letter-spacing: 0.62px;
}

.invite_info p {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.invite_btn {
    color: #000;
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px;
    cursor: pointer;
    background: none;
}

.invite_paragraph a {
    color: var(--color-orange);
}

.invite_paragraph a:hover {
    text-decoration: underline;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .invite_inner {
        width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .invite_container {
        padding: 97.5px 15px;
    }

    .invite_content {
        width: 90%;
        gap: 60px;
    }
}

@media screen and (max-width: 600px) {
    .invite_content {
        flex-direction: column-reverse;
    }

    .invite_content img {
        height: 350px;
        width: 350px;
    }
}