.services_container {
    display: flex;
    width: 100%;
    padding: 97.5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-white);
}

.services_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.services_content {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.services_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--color-black);
    font-family: Helvetica;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 73.2px;
    letter-spacing: 1.22px;
}

.card_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.card {
    display: flex;
    padding: 48px;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    background: #fefefe;
    width: 100%;
}

.card_reverse {
    display: flex;
    padding: 48px;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    background: #fefefe;
    width: 100%;
}

.card_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
}

.card_content p {
    color: var(--color-black);
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.card_image img {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
}

/* MEDIA QUERIES (LARGER THAN 1080p) */
@media screen and (min-width: 1921px) {
    .services_inner {
        max-width: 1920px;
        margin: 0 auto;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .services_heading {
        font-size: 1rem;
        line-height: 3rem;
        padding: 0 1%;
    }

    .services_content {
        width: 90%;
    }

    .card {
        flex-direction: column;
        gap: 30px;
    }

    .card_reverse {
        flex-direction: column;
        gap: 30px;
    }

    .card_content p {
        font-size: 1.2rem;
        line-height: 24px;
    }
}

@media screen and (max-width: 600px) {
    .card {
        flex-direction: column;
        gap: 30px;
    }

    .card_reverse {
        flex-direction: column;
        gap: 30px;
    }

    .card_content p {
        font-size: 1rem;
    }
}